//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import KirbyLink from './kirbylink.vue'
export default {
    name: "getthatjobsorted",
    props:{
      content: Object
    },
    components:{
      KirbyLink
    }
}
